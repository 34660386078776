import { Grid } from '@material-ui/core';
import React from 'react';
import Particles from "react-tsparticles";

function Landing() {

    return (
        <React.Fragment>
            <div id="tsparticles" className="banner">
                <Grid container justify="center" alignItems="center" direction="column" style={{ height: "100%" }}>
                    <Grid item>
                        <p>
                            <h1 style={{
                                fontSize: "7em",
                                fontWeight: 800,
                                letterSpacing: 0,
                                color: "#fff"
                            }}>
                                <span style={{
                                    color: "#fc6f5c",
                                    fontWeight: 800
                                }}>The</span>
                                <span style={{
                                    //color: "#fc6f5c"
                                }}>
                                    Stanley
                                </span>
                                <span style={{
                                    color: "#fc6f5c",
                                    fontWeight: 800
                                }}>.Dev</span></h1>
                        </p>
                    </Grid>
                </Grid>
            </div>
            <Particles
                id="tsparticles2"
                options={
                    {
                        background: {
                            color: {
                                value: "red",
                            },
                        },
                        "particles": {
                            "number": {
                                "value": 100,
                                "density": {
                                    "enable": true,
                                    "value_area": 800
                                }
                            },
                            "color": {
                                "value": "#fff"
                            },
                            "shape": {
                                "type": "circle",
                                "stroke": {
                                    "width": 0,
                                    "color": "#fff"
                                },
                                "polygon": {
                                    "nb_sides": 5
                                },
                                "image": {
                                    "src": "img/github.svg",
                                    "width": 100,
                                    "height": 100
                                }
                            },
                            "opacity": {
                                "value": 0.5,
                                "random": false,
                                "anim": {
                                    "enable": false,
                                    "speed": 1,
                                    "opacity_min": 0.1,
                                    "sync": false
                                }
                            },
                            "size": {
                                "value": 5,
                                "random": true,
                                "anim": {
                                    "enable": false,
                                    "speed": 40,
                                    "size_min": 0.1,
                                    "sync": false
                                }
                            },
                            "line_linked": {
                                "enable": true,
                                "distance": 150,
                                "color": "#fff",
                                "opacity": 0.4,
                                "width": 1
                            },
                            "move": {
                                "enable": true,
                                "speed": 6,
                                "direction": "top",
                                "random": false,
                                "straight": false,
                                "out_mode": "out",
                                "attract": {
                                    "enable": false,
                                    "rotateX": 600,
                                    "rotateY": 600
                                }
                            }
                        },
                        "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "repulse"
                                },
                                "onclick": {
                                    "enable": true,
                                    "mode": "push"
                                },
                                "resize": true
                            },
                            "modes": {
                                "grab": {
                                    "distance": 250,
                                    "line_linked": {
                                        "opacity": 1
                                    }
                                },
                                "bubble": {
                                    "distance": 600,
                                    "size": 80,
                                    "duration": 2,
                                    "opacity": 8,
                                    "speed": 3
                                },
                                "repulse": {
                                    "distance": 200
                                },
                                "push": {
                                    "particles_nb": 4
                                },
                                "remove": {
                                    "particles_nb": 2
                                }
                            }
                        },
                        "retina_detect": true,
                        "config_demo": {
                            "hide_card": false,
                            "background_color": "#b61924",
                            "background_image": "",
                            "background_position": "50% 50%",
                            "background_repeat": "no-repeat",
                            "background_size": "cover"
                        }
                    }
                }
            />
        </React.Fragment>
    );
}

export default Landing;